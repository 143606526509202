<template>
  <page-template>
    <template slot="title">{{ this.$t("product_groups") }}</template>
    <template slot="content">
      <v-row align="start" justify="start" class="ma-0">
        <product-group-view
            v-for="group in parentProductGroups"
            :key="group.codePath"
            style="margin: 8px; max-width: 298px;"
            @click.native="onClickProductGroups(group.codePath)"
        >
          <template slot="productGroupsName" style="">{{ group.name }}</template>
        </product-group-view>
        <not-found v-show="noData"/>
      </v-row>
    </template>
  </page-template>
</template>

<script>
import PageTemplate from "../../templates/PageTemplate";
import ProductGroupView from "../../partial/ProductGroupView";
import Constants from "@/Constants";
import NotFound from "../scada/NotFound";
export default {
  name: "ProductGroups",
  components: {PageTemplate, ProductGroupView ,NotFound},
  created() {
    this.$store.dispatch("showProgressBar");
    this.getProductGroups();
  },
  data() {
    return {
      parentProductGroups: [],
      allProductGroups: [],
      noData: false
    };
  },
  methods: {
    onClickProductGroups(item) {
      this.$router.push({name: "Product", params: {codePath: item}});
      console.log(item);
    },
    getProductGroups() {
      this.$http.get(Constants.apiMethod.productGroups).then((response) => {
        if(response.data.length===0){
          this.noData = !this.noData;
          this.$store.dispatch("hideProgressBar");
        }else {
        this.allProductGroups = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.allProductGroups.forEach((group) => {
          if (group.codePath.length === 3) {
            this.parentProductGroups.push(group)
          }
        })
        this.$store.dispatch("hideProgressBar");
        }
      })
    },
  },
};
</script>

<style scoped></style>
